// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import vuetify from './plugins/vuetify'
import i18n from './i18n'

Vue.config.productionTip = false

/*
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
*/

const app = new Vue({
  data: {
    testVal: 'Karousels',
    idTok: '',
    signedUser: {
      info: '',
      creds: '',
    },
  },
  // el: '#app',
  beforeCreate: function () {/* --
    this.$zkHttp.get().then(response => {
      store.state.cookie = response.data.joke
      console.log('Yawn - ' + store.state.cookie)
    })
    --*/
    console.log(`-- In beforeCreate`);
  },
  mounted: function () {
    /*
    console.log('Vue mounted. Getting ready - ' + this.$zkStore.state.cookie)
    console.log('Version - ' + this.$zKversion.app + this.$zKversion.rev)
    // this.$zkLogmeOut()
    this.$zkHttp.defaults.baseURL = 'http://10.117.23.20/v1/api/zka/' // for now the local VM on hobby room
    this.$zkHttp.get('version').then(response => {
      const x = JSON.stringify(response.data)
      console.log('ZK Remote version - ' + x)
    }) */
    console.log('Vue mounted. Getting ready - ');
  },
  methods: {
    // fetchZKapi: function (path) {
      // Add the remote api call here

    //},
  },
  render: h => h(App),
  router,
  store,
  vuetify,
  i18n,
});


app.$mount('#app')
// -------- EOF LL  9.10.21
